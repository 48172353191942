var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "iframe-container-box" }, [
    _c(
      "div",
      { staticClass: "searchForm" },
      [
        _c("renderForm", {
          ref: "ruleFormRef",
          attrs: {
            formData: _vm.searchForm,
            list: _vm.ruleForm,
            labelWidth: "70",
          },
          on: {
            "update:formData": function ($event) {
              _vm.searchForm = $event
            },
            "update:form-data": function ($event) {
              _vm.searchForm = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "mallCode",
              fn: function () {
                return [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "当前项目", prop: "mallCode" } },
                    [
                      _c("rs-select-mall", {
                        attrs: { clearable: false, host: "/api/user" },
                        model: {
                          value: _vm.searchForm.mallCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "mallCode", $$v)
                          },
                          expression: "searchForm.mallCode",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "operation",
              fn: function () {
                return [
                  _c(
                    "rs-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.buttonLoading,
                      },
                      on: { click: _vm.search },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "rs-button",
                    {
                      attrs: {
                        type: "text",
                        size: "small",
                        loading: _vm.buttonLoading,
                      },
                      on: { click: _vm.clear },
                    },
                    [_vm._v("重置")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c(
              "rs-row",
              [
                _c(
                  "rs-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "rs-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.addwarning },
                      },
                      [_vm._v("新增")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "rs-table",
          {
            staticClass: "my-table",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              "highlight-current-row": "",
              border: "",
              "tooltip-effect": "light",
              height: "calc(100vh - 150px)",
            },
            on: { "row-dblclick": _vm.handleRowClick },
          },
          [
            _c("rs-table-column", {
              attrs: {
                label: "序号",
                type: "index",
                align: "left",
                width: "70",
              },
            }),
            _c("rs-table-column", {
              attrs: { label: "项目名称", prop: "mallName", align: "left" },
            }),
            _c("rs-table-column", {
              attrs: { label: "预警类型", prop: "configType", align: "left" },
            }),
            _c("rs-table-column", {
              attrs: { label: "预警阈值", align: "left", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            scope.row.configValue +
                              _vm.warnDateTypeList[scope.row.configValue1]
                          ) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("rs-table-column", {
              attrs: {
                label: "最近一次修改信息",
                prop: "modifyInfo",
                align: "left",
              },
            }),
            _c("rs-table-column", {
              attrs: { label: "操作", width: "120", align: "left" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "rs-button",
                        {
                          attrs: { size: "small", type: "text" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.del(scope)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "align_right marginTo10" },
          [
            _c("rs-pagination", {
              attrs: {
                "current-page": _vm.currentPage,
                "page-sizes": [10, 20, 50, 100],
                "page-size": _vm.pageSize,
                layout: "prev, pager, next,sizes, jumper",
                total: _vm.total,
                background: "",
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }