<template>
  <div class="iframe-container-box">
    <div class="searchForm">
      <renderForm :formData.sync="searchForm" :list="ruleForm" ref="ruleFormRef" labelWidth="70">
        <template v-slot:mallCode>
          <rs-form-item label="当前项目" prop="mallCode">
            <rs-select-mall :clearable="false" host="/api/user" v-model="searchForm.mallCode"></rs-select-mall>
          </rs-form-item>
        </template>
        <template v-slot:operation>
          <rs-button type="primary" size="small" @click="search" :loading="buttonLoading">查询</rs-button>
          <rs-button type="text" size="small" @click="clear" :loading="buttonLoading">重置</rs-button>
        </template>
      </renderForm>
    </div>
    <div>
      <div class="box">
        <rs-row>
          <rs-col :span="24">
            <rs-button type="primary" size="mini" @click="addwarning">新增</rs-button>
          </rs-col>
        </rs-row>
      </div>
      <rs-table
        class="my-table"
        :data="tableData"
        highlight-current-row
        border
        tooltip-effect="light"
        @row-dblclick="handleRowClick"
        height="calc(100vh - 150px)"
        style="width: 100%;"
      >
        <rs-table-column label="序号" type="index" align="left" width="70"></rs-table-column>
        <rs-table-column label="项目名称" prop="mallName" align="left"></rs-table-column>
        <rs-table-column label="预警类型" prop="configType" align="left"></rs-table-column>
        <rs-table-column label="预警阈值" align="left" width="100">
          <template slot-scope="scope">
            {{ scope.row.configValue + warnDateTypeList[scope.row.configValue1] }}
          </template>
        </rs-table-column>
        <rs-table-column label="最近一次修改信息" prop="modifyInfo" align="left"></rs-table-column>
        <rs-table-column label="操作" width="120" align="left">
          <template slot-scope="scope">
            <rs-button size="small" @click.stop="del(scope)" type="text">删除</rs-button>
          </template>
        </rs-table-column>
      </rs-table>
      <div class="align_right marginTo10">
        <rs-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="prev, pager, next,sizes, jumper"
          :total="total"
          background
        ></rs-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Utils from '@/utils/utils'
import Api from '@/api/reportServer'
import Api1 from '@/api/api'
export default {
  data() {
    return {
      buttonLoading: false,
      tableData: [
        // { name: '青岛项目', type: '招商合同到期预警', no: '多经合同到期预警', time: '2020-15-15' }
      ],
      warnDateTypeList: {}, //预警天数的单位:天、月、日等
      currentPage: 1,
      pageSize: 10,
      total: 0,
      searchForm: {
        mallCode: ''
      },
      ruleForm: [
        {
          slot: 'mallCode'
        },
        {
          slot: 'operation'
        }
      ]
    }
  },
  created() {
    this.getConfitType() //获取获取预警天数的单位
  },
  methods: {
    search() {
      if (!this.searchForm.mallCode) {
        this.$message.warning('请先选择项目')
      } else {
        this.currentPage = 1
        this.getList()
      }
    },
    async getConfitType() {
      let data = await Api1.getWarningconfigType({ dictType: 'time_unit' }) //获取预警天数的单位:天、月、日等
      data.data.data.forEach(item => {
        this.warnDateTypeList[item.dictValue] = item.dictName
      })
      this.getList()
    },
    getList(params) {
      if (!params) {
        params = {
          pageSize: this.pageSize,
          pageNum: this.currentPage
        }
      }
      params.bizType = 1
      if (this.searchForm.mallCode) params.mallCode = this.searchForm.mallCode
      Api1.queryWarinngList(params).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.total
      })
    },
    handleRowClick(row) {
      this.$router.push('/editearlywarning?id=' + row.id)
    },
    addwarning() {
      this.$router.push('/editearlywarning?type=add')
    },
    del(scope) {
      this.$confirmDialog({ title: '提示', textBody: `您确定要删除吗？`, width: '35%', iconType: 'warning', btnType: 2 })
        .then(async () => {
          let params = {
            bizType: 1,
            id: scope.row.id
          }
          let res = await Api1.delWarning(params)

          if (res.data.code === 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getList()
          }
        })
        .catch(err => {})
    },
    clear() {
      this.searchForm.mallCode = ''
    },
    handleCurrentChange(val) {
      this.currentPage = val
      let params = {
        pageSize: this.pageSize,
        pageNum: this.currentPage
      }
      this.getList(params)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .rs-form-item--small.rs-form-item {
  margin-bottom: 0;
}
</style>
